import React, { useState } from 'react';
import Slider from 'react-slick';
import './App.css';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
const App = () => (
  <div className="App">
    <Header className="header" />
    <div className="main-content">
      <section id="home"><Home /></section>
      <section id="services"><Services /></section>
      <section id="technologies"><Technologies /></section>
      <section id="about-us"><AboutUs /></section>
      <section id="process"><Process /></section>
      {/* <section id="testimonials"><Testimonials /></section> */}
      <section id="contact"><Contact /></section>
    </div>
    <Footer />
  </div>
);






const Header = () => {
  const [isNavVisible, setNavVisible] = useState(false);

const toggleNavVisibility = () => {
  setNavVisible(!isNavVisible);
};

const hideNav = () => {
  setNavVisible(false);
};

  return (
    <header className="header">
      <div className="logo-container">
        <img src="/logo.png" alt="RAK Analytics Logo" className="logo" />
        <h1 className="title">
          <span>RAK Analytics</span>
          <span>Solutions Pvt Ltd</span>
        </h1>
      </div>
      <div className="hamburger" onClick={toggleNavVisibility}>
  &#9776; {/* Hamburger icon */}
</div>
<nav className={`nav ${isNavVisible ? 'show' : ''}`}>
  <ul>
    <li><a href="#home" onClick={hideNav}>Home</a></li>
    <li><a href="#services" onClick={hideNav}>Services</a></li>
    <li><a href="#technologies" onClick={hideNav}>Technologies</a></li>
    <li><a href="#about" onClick={hideNav}>About Us</a></li>
    <li><a href="#process" onClick={hideNav}>Process</a></li>
    <li><a href="#contact" onClick={hideNav}>Contact</a></li>
  </ul>
</nav>
    </header>
  );
};




const Home = () => (
  <section id="home" className="home">
    <ImageSlider />
    <div className="home-content">
      <h1>Welcome to Our Software Service</h1>
      <p>We deliver cutting-edge software solutions tailored to your business needs.</p>
      <button className="cta-button">Get Started</button>
    </div>
  </section>
);

const ImageSlider = () => {
  return (
    <Carousel
      showThumbs={false}
      autoPlay
      infiniteLoop
      interval={3000}
      showArrows={false}
      showStatus={false}
    >
      <div>
        <img src="/images/cloud.jpg" alt="Software development" className="slider-image" />
      </div>
      <div>
        <img src="/images/coding.jpg" alt="Coding" className="slider-image" />
      </div>
      <div>
        <img src="/images/software.jpg" alt="Technology" className="slider-image" />
      </div>
      <div>
        <img src="/images/technology.jpg" alt="Cloud computing" className="slider-image" />
      </div>
    </Carousel>
  );
};
const Services = () => (
  <section id="services" className="services">
    <h2>Our Services</h2>
    <div className="service-list">
      <div className="service-item">
        <h3>Custom Software Development</h3>
        <p>Building scalable and efficient custom software solutions that fit your specific requirements.</p>
      </div>
      <div className="service-item">
        <h3>Web and Mobile App Development</h3>
        <p>Creating responsive web applications and mobile apps that offer a seamless user experience.</p>
      </div>
      <div className="service-item">
        <h3>Cloud Solutions</h3>
        <p>Offering cloud computing services to enhance your business flexibility and reduce costs.</p>
      </div>
      <div className="service-item">
        <h3>IT Consulting</h3>
        <p>Providing expert IT consulting to help you navigate the complexities of the digital world.</p>
      </div>
    </div>
  </section>
);

const Technologies = () => (
  <section id="technologies" className="technologies">
    <h2>Technologies We Use</h2>
    <div className="tech-icons">
      <div className="tech-item">React</div>
      <div className="tech-item">Node.js</div>
      <div className="tech-item">AWS</div>
      <div className="tech-item">Docker</div>
      <div className="tech-item">Kubernetes</div>
      <div className="tech-item">Python</div>
    </div>
  </section>
);

const AboutUs = () => (
  <section id="about" className="about">
    <h2>About Us</h2>
    <p>We are a team of experienced software developers and IT professionals committed to delivering high-quality software solutions. Our mission is to empower businesses with technology, enabling them to achieve their goals and stay ahead in the competitive market.</p>
  </section>
);

const Process = () => (
  <section id="process" className="process">
    <h2>Our Process</h2>
    <div className="process-steps">
      <div className="step">
        <h3>1. Discovery</h3>
        <p>Understanding your business needs and defining the project scope.</p>
      </div>
      <div className="step">
        <h3>2. Design</h3>
        <p>Creating a user-centric design that aligns with your brand and objectives.</p>
      </div>
      <div className="step">
        <h3>3. Development</h3>
        <p>Developing the solution using the latest technologies and best practices.</p>
      </div>
      <div className="step">
        <h3>4. Testing</h3>
        <p>Ensuring the solution is bug-free and meets the highest quality standards.</p>
      </div>
      <div className="step">
        <h3>5. Deployment</h3>
        <p>Deploying the solution and providing ongoing support to ensure its success.</p>
      </div>
    </div>
  </section>
);

// const Testimonials = () => (
//   <section id="testimonials" className="testimonials">
//     <h2>Testimonials</h2>
//     <div className="testimonial-list">
//       <div className="testimonial-item">
//         <p>"The team at Software Service Provider delivered a solution that exceeded our expectations. Their professionalism and expertise are unmatched!"</p>
//         <h4>Client A</h4>
//       </div>
//       <div className="testimonial-item">
//         <p>"Their ability to understand our requirements and provide a tailored solution was impressive. We highly recommend their services."</p>
//         <h4>Client B</h4>
//       </div>
//     </div>
//   </section>
// );

const Contact = () => (
  <section id="contact" className="contact">
    <h2>Contact Us</h2>
    <p>If you're interested in working with us or have any questions, feel free to reach out.</p>
    <p>Mobile no 9900173022</p>
    <p>Mail to info@rakanalytics.com</p>
    <p>Near 95/60, Cave Temple Rd,opp. to Bgs National Public School, Muthurayya Swamy Layout,Hulimavu,Bengalore,Karnataka</p>
  </section>
);

const Footer = () => (
  <footer className="footer">
    <p>&copy; 2024 Software Service Provider. All Rights Reserved.</p>
  </footer>
);

export default App;
